<template>
  <div class="deliver">
    <van-tabs v-model="active" class="hidden">
      <van-tab :title="$t('Deliver.vw7xme')"></van-tab>
      <van-tab :title="$t('Deliver.szizjd')"></van-tab>
    </van-tabs>
    <section class="padding">
      <div class="card round" v-show="active === 0">
        <ul class="list">
          <li class="list-item flexbox header">
            <van-checkbox
              v-model="checkAll"
              @change="onCheckAll"
            ></van-checkbox>
            <div class="flex-fill min-col-30 title">
              {{ $t('Deliver.vdo6nj') }}
            </div>
            <div class="flex-fill min-col-30 title">
              {{ $t('Deliver.zwrdal') }}
            </div>
            <div class="flex-fill min-col-10 title">
              {{ $t('Deliver.总货值') }}
            </div>
            <div class="flex-fill min-col-10 title">
              {{ $t('Deliver.箱数') }}
            </div>
          </li>
          <li
            class="list-item flexbox"
            v-for="item in packageList"
            :key="item.id"
          >
            <van-checkbox
              v-model="item.checked"
              @change="doItemCheck($event, item)"
            ></van-checkbox>
            <div class="flex-fill min-col-30">
              {{ item.delivery_no }}
            </div>
            <div class="flex-fill min-col-30">
              {{ item.goods_name }}
            </div>
            <div class="flex-fill min-col-10">
              {{ item.fTotalDeclarePrice }}
            </div>
            <div class="flex-fill min-col-10">{{ item.iBags }}</div>
          </li>
        </ul>
        <footer class="flexbox align-center space-bt">
          <div class="f-12">
            显示第{{ (pageIndex - 1) * pageSize + 1 }}到第{{
              (pageIndex - 1) * pageSize + pageSize > total
                ? total
                : (pageIndex - 1) * pageSize + pageSize
            }}条记录，总共{{ total }}条记录
          </div>
          <van-pagination
            v-model="pageIndex"
            prev-text=" "
            next-text=" "
            :total-items="total"
            :items-per-page="pageSize"
            @change="doChangePackageList"
          />
        </footer>
      </div>
      <div class="card round" hidden>
        <van-field v-model="sender.name" label="寄件人"></van-field>
        <van-field v-model="sender.tel" label="电话"></van-field>
      </div>

      <div class="card address flexbox align-center round">
        <div class="tag receiver">收</div>
        <div class="flex-fill">
          <div class="flexbox">
            <span>{{ receiver.name || '请选择' }}</span>
            <span>{{ receiver.tel }}</span>
          </div>
          <address class="f-12">{{ receiver.address }}</address>
        </div>
        <div
          class="btn-pick"
          @click="
            $router.push({ name: 'AddressList', query: { owner: 'receiver' } })
          "
        >
          选择
        </div>
      </div>
    </section>
    <ul class="props">
      <li class="list-item flexbox" v-if="active === 1">
        <span class="label">{{ $t('Deliver.d2rdz7') }}</span>
        <van-field v-model="name" placeholder="请填写货物名称"></van-field>
      </li>
      <li class="list-item flexbox">
        <span class="label">{{ $t('Deliver.专线选择') }}</span>
        <div
          @click="showLinePicker = true"
          class="ml-auto"
          :class="{ 'color-3': distLineText }"
        >
          {{ distLineText || '点击选择发货专线' }}
        </div>
      </li>
      <li class="list-item flexbox">
        <span class="label">{{ $t('Deliver.124niu') }}</span>
        <van-radio-group
          v-model="packageType"
          class="flex-fill"
          direction="horizontal"
        >
          <van-radio
            v-for="item in packageTypeList"
            :key="item.value"
            :name="item.value"
            class="option"
            >{{ item.name }}</van-radio
          >
        </van-radio-group>
      </li>
      <li class="list-item flexbox">
        <span class="label">{{ $t('Deliver.m2lxd9') }}</span>
        <van-radio-group
          v-model="transType"
          class="flex-fill"
          direction="horizontal"
          :disabled="selectedLineId > 0"
        >
          <van-radio
            v-for="item in transTypeList"
            :key="item.value"
            :name="item.value"
            class="option"
            >{{ item.name }}</van-radio
          >
        </van-radio-group>
      </li>
      <li class="list-item flexbox">
        <span class="label">{{ $t('Deliver.ttotwd') }}</span>
        <div
          @click="showPicker = selectedLineId <= 0 ? true : false"
          class="ml-auto"
          :class="{ 'color-3': destinationText }"
        >
          {{ destinationText || '点击选择目的地' }}
        </div>
      </li>
      <li class="list-item">
        <van-field
          v-model="note"
          rows="2"
          type="textarea"
          class="border"
          :placeholder="$t('Deliver.6drhhq')"
          show-word-limit
        />
      </li>
    </ul>
    <div class="footer-btn">
      <van-button type="info" @click="onSubmit">{{
        $t('Deliver.0n2ao8')
      }}</van-button>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showLinePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="lineList"
        @confirm="onLineSelected"
        @cancel="showLinePicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  getPackageList,
  submitOrder,
  getCommonItemList,
  getLineList,
} from '@/api/deliver.js';
export default {
  name: 'Deliver',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'AddressList') {
        vm[from.query.owner] = from.query;
      }
    });
  },
  data() {
    return {
      active: 0,
      checkAll: false,
      pageIndex: 1,
      pageSize: 100,
      total: 0,
      name: '',
      transType: 0,
      packageType: 0,
      packageTypeList: [],
      transTypeList: [],
      note: '',
      datasource: [],
      sender: {
        name: '',
        tel: '',
        address: '',
      },
      receiver: {
        name: '',
        tel: '',
        address: '',
      },
      showPicker: false,
      destinationText: '',
      destinationOptions: [],
      checkedPackagesList: [],
      lineOptions: [],
      showLinePicker: false,
      distLineText: '',
      selectedLineId: -1, //增加专线ID
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    columns() {
      return this.destinationOptions.map(item => item.name);
    },
    lineList() {
      return this.lineOptions.map(item => item.name);
    },
    packageList() {
      return this.datasource;
      // return this.datasource.slice(
      //   (this.pageIndex - 1) * this.pageSize,
      //   this.pageIndex * this.pageSize
      // );
    },
  },
  watch: {
    userInfo: {
      handler(value) {
        if (value.PK_ID) {
          this.sender.name = value.name;
          this.sender.tel = value.tel;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getPackageList({
        state: 0,
        limit: this.pageSize,
        offset: this.pageIndex - 1,
      }).then(res => {
        this.datasource = res.rows.map(item => {
          return {
            ...item,
            checked: false,
          };
        });
        this.total = res.total;
      });

      getLineList({
        keyword: '',
        status: '',
      }).then(res => {
        if (res.Result == true) {
          this.lineOptions = res.DataDict['list'].map(item => {
            return {
              name: item.sName,
              value: item.PK_ID,
              targetPlaceId: item.FK_EndPlaceID,
              targetPlaceName: item.sTargetPlace,
              transTypeId: item.FK_TransWayID,
              transTypeName: item.sTransWay,
            };
          });
          //专线选额默认，默认不启用
          // this.onLineSelected(this.lineOptions[0].value, 0);
        }
      });

      //目的地
      getCommonItemList('007').then(res => {
        this.destinationOptions = res.map(item => {
          return {
            name: item.text,
            value: item.value,
          };
        });
      });

      //货物类型，敏感货，普货
      getCommonItemList('008').then(res => {
        this.packageTypeList = res.map(item => {
          return {
            name: item.text,
            value: item.value,
          };
        });
        this.packageType = this.packageTypeList[0].value;
      });
      //运输方式
      getCommonItemList('002').then(res => {
        this.transTypeList = res.map(item => {
          return {
            name: item.text,
            value: item.value,
          };
        });
        this.transType = this.transTypeList[0].value;
      });
    },

    //翻页
    doChangePackageList() {
      getPackageList({
        state: 0,
        limit: this.pageSize,
        offset: (this.pageIndex - 1) * this.pageSize,
      }).then(res => {
        this.datasource = res.rows.map(item => {
          return {
            ...item,
            checked: false,
          };
        });
        this.total = res.total;
        //原有的所有的临时数据
        let tmp_pkglist = [];
        this.checkedPackagesList.forEach(it => {
          tmp_pkglist.push(it);
        });
        console.log('####1');
        console.log(tmp_pkglist);
        //取消了临时数据
        this.checkAll = false;
        console.log(tmp_pkglist);
        setTimeout(() => {
          //做已选择的匹配
          tmp_pkglist.forEach(item => {
            var existarr = this.packageList.filter(el => el.PK_ID == item);
            if (existarr != null && existarr.length > 0) {
              existarr[0].checked = true;
            } else {
              var chkarr = this.checkedPackagesList.filter(el => el == item);
              if (chkarr == null || chkarr.length == 0) {
                this.checkedPackagesList.push(item);
              }
            }
          });
        }, 100);
        // this.packageList.forEach(item => {
        //   var existarr = tmp_pkglist.filter(el => el == item.PK_ID);
        //   if (existarr != null && existarr.length > 0) {
        //     item.checked = true;
        //   }
        // });
        // var checklist = this.packageList.filter(el => el.checked == true);
        // if (checklist != null && checklist.length == this.packageList.length) {
        //   this.checkAll = true;
        // }
      });
    },

    //单个单个选中的操作函数
    doItemCheck($event, item) {
      if (item.checked == true) {
        //选中
        var existarr = this.checkedPackagesList.filter(el => el == item.PK_ID);
        if (existarr == null || existarr.length == 0) {
          this.checkedPackagesList.push(item.PK_ID);
        }
      } else {
        this.checkedPackagesList = this.checkedPackagesList.filter(
          el => el != item.PK_ID
        );
      }

      // var checklist = this.packageList.filter(el => el.checked == true);
      // if (checklist != null && checklist.length == this.packageList.length) {
      //   this.checkAll = true;
      // }

      console.log(this.checkedPackagesList);
    },

    onLineSelected(value, index) {
      //选择专线
      this.selectedLineId = value;
      this.selectedLineId = this.lineOptions[index].value;
      this.distLineText = this.lineOptions[index].name;
      this.showLinePicker = false;
      //根据选择的专线，设置对应的目的地，运输方式

      this.destination = this.lineOptions[index].targetPlaceId;
      this.destinationText = this.lineOptions[index].targetPlaceName;
      this.transType = this.lineOptions[index].transTypeId;
    },
    onCheckAll(value) {
      //取消全选,全选
      this.packageList.forEach(item => {
        item.checked = value;
      });
    },
    onConfirm(value, index) {
      this.destination = this.destinationOptions[index].value;
      this.destinationText = this.destinationOptions[index].name;
      this.showPicker = false;
    },

    reset() {
      this.loadData();
      this.receiver = {};
      this.note = '';
      this.packageType = this.packageTypeList[0].value;
      this.transType = this.transTypeList[0].value;
      this.onCheckAll(false);
      this.checkAll = false;
    },
    onSubmit() {
      if (this.selectedLineId <= 0) {
        this.$toast('请选择专线');
        return;
      }
      if (this.checkedPackagesList.filter(item => item > 0).length === 0) {
        this.$toast('请选择包裹');
        return;
      }
      if (!this.receiver.name) {
        this.$toast('请选择收件人');
        return;
      }
      if (!this.destination) {
        this.$toast('请选择目的地');
        return;
      }
      this.$toast.loading({ duration: 0 });
      submitOrder({
        destination: this.destination,
        transportType: this.transType,
        sender: this.sender.name,
        senderTel: this.sender.tel,
        name: this.name,
        type: this.packageType,
        receiverId: this.receiver.id,
        remark: this.note,
        lineId: this.selectedLineId,
        packageIds: this.checkedPackagesList.map(item => item),
      })
        .then(res => {
          if (res.Result) {
            this.$toast(res.Info);
            this.reset();
          } else {
            this.$toast.fail(res.Info);
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.deliver {
  color: #333;
  margin-bottom: rem(20px);
}
.padding {
  padding: rem(20px);
}
.header .title {
  font-size: rem(24px);
}
.min-col-30 {
  min-width: 30%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: rem(22px);
  padding-right: 3px;
}
.min-col-20 {
  min-width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: rem(22px);
  padding-right: 3px;
}
.min-col-10 {
  min-width: 10%;
  font-size: rem(22px);
  text-align: center;
}
.card {
  box-shadow: 0px 2px 10px 10px rgba(14, 48, 53, 0.1);
  & + .card {
    margin-top: rem(20px);
  }
  background-color: #fff;
  &.address {
    padding: rem(20px);
  }
}
.van-checkbox {
  margin-right: rem(30px);
}
.list-item {
  padding: rem(20px);
  border-bottom: 1px solid #e6e6e6;
  &.header {
    background-color: #9c9bb4;
    color: #fff;
    border-top-left-radius: rem(15px);
    border-top-right-radius: rem(15px);
  }
}
footer {
  color: #b8b8b8;
  padding: 0 rem(20px);
}
/deep/ .van-pagination__item {
  color: inherit;
  background-color: transparent;
  &::after {
    border-width: 0;
  }
}
/deep/ .van-pagination__item--active {
  background-color: transparent;
  color: $theme-color;
}
.tag {
  width: rem(60px);
  height: rem(60px);
  line-height: rem(60px);
  text-align: center;
  color: #fff;
  border-radius: rem(10px);
  margin-right: rem(20px);
  font-size: 20px;
  &.sender {
    background-color: $theme-color;
  }
  &.receiver {
    background-color: #f48f0d;
  }
}
address {
  font-style: normal;
  line-height: 1.5;
  margin-top: rem(24px);
  color: #9c9bb4;
}
.btn-pick {
  color: $theme-color;
  margin-left: rem(70px);
  margin-right: rem(20px);
  white-space: nowrap;
}
.option {
  border-radius: rem(15px);
  height: rem(40px);
  line-height: rem(40px);
  border: 1px solid #b8b8b8;
  flex: 1;
  display: block;
  text-align: center;
  & + .option {
    margin-left: rem(40px);
  }
  &[aria-checked='true'] {
    border-color: $theme-color;
    color: $theme-color;
  }
}
.props {
  background-color: #fff;
  color: #b8b8b8;
  .label {
    margin-right: rem(50px);
    white-space: nowrap;
  }
}
// .van-cell {
//   padding-top: 0;
//   padding-bottom: 0;
// }
/deep/ .van-radio__label {
  color: inherit;
}
/deep/ .van-radio__icon {
  display: none;
}
.van-field {
  &.border {
    border: 1px solid #b8b8b8;
    border-radius: rem(15px);
  }
}
.footer-btn {
  margin: rem(24px);
  text-align: center;
  .van-button {
    width: 80%;
  }
}
</style>
